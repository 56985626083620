import getAdditionalContext from './getAdditionalContext'
import * as Sentry from '@sentry/nextjs'
import { type Reporter } from '@betterplace/error-reporting'

const report: Reporter = (error, context) => {
  const additionalContext = getAdditionalContext()
  // eslint-disable-next-line import/namespace
  Sentry.captureException(error, { ...context, data: additionalContext })
}
export default report
