'use client'
import Cookies, { CookieChangeListener } from 'universal-cookie'
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'
import { TRACKING_COOKIE_NAME, TRACKING_UTM_COOKIES_MAX_AGE, TRACKING_UTM_PARAMS, TrackingCookieType } from './types'
import { useCallback, useEffect, useState } from 'react'

const cookies = new Cookies()

function setUtmCookies(domain: string, params: ReadonlyURLSearchParams) {
  for (const name of TRACKING_UTM_PARAMS) {
    const param = params.get(name)
    if (!param) continue
    cookies.set(`tracking_${name}`, param, {
      maxAge: TRACKING_UTM_COOKIES_MAX_AGE,
      secure: true,
      httpOnly: true,
      sameSite: 'lax',
      domain: `.${domain}`,
    })
  }
}

function useTrackingCookie(serverSideCookieValue: TrackingCookieType, domain: string) {
  const [trackingCookieValue, setTrackingCookieValue] = useState<TrackingCookieType>(serverSideCookieValue)
  const searchParams = useSearchParams()

  const handleCookieChange: CookieChangeListener = useCallback((cookie) => {
    if (cookie.name !== TRACKING_COOKIE_NAME) {
      return
    }
    setTrackingCookieValue(cookie.value as TrackingCookieType)
  }, [])

  const setCookie = useCallback(
    (value: TrackingCookieType) => {
      cookies.set(TRACKING_COOKIE_NAME, value, {
        path: '/',
        expires: new Date(2099, 1, 1),
        domain: `.${domain}`,
      })
      setTrackingCookieValue(value)
      if (value !== 'accepted' || !searchParams) return

      setUtmCookies(domain, searchParams)
    },
    [searchParams]
  )

  useEffect(() => {
    const value = cookies.get<TrackingCookieType | 'yes' | 'no'>(TRACKING_COOKIE_NAME)
    if (value && ['yes', 'no', 'accepted'].includes(value)) {
      cookies.remove(TRACKING_COOKIE_NAME, { path: '/' })
      if (value === 'no') return
      setCookie('accepted')
    }
  }, [setCookie])

  useEffect(() => {
    const value = cookies.get<TrackingCookieType>(TRACKING_COOKIE_NAME)
    setTrackingCookieValue(value)
    cookies.addChangeListener(handleCookieChange)
    return () => {
      cookies.removeChangeListener(handleCookieChange)
    }
  }, [handleCookieChange])

  return [trackingCookieValue, setCookie] as const
}

export default useTrackingCookie
