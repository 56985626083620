/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any */
import sanitize from 'sanitize-html'
import { unescape } from 'lodash'
import { z } from 'zod'

function isTextObject(input: unknown): input is { 0: string; 1: string } {
  return typeof input === 'object' && !!input && 0 in (input as any) && 1 in (input as any)
}

export function getRequiredCappedString(capAt: number) {
  return z.preprocess(
    (v) => (typeof v === 'number' ? String(v) : v),
    z
      .string()
      .min(1)
      .max(capAt)
      .transform((val) => unescape(sanitize(val)))
  )
}
export function getOptionalCappedString(capAt: number) {
  return z.preprocess(
    (v) => (typeof v === 'number' ? String(v) : v),
    z
      .string()
      .min(0)
      .max(capAt)
      .optional()
      .transform((val) => val && unescape(sanitize(val)))
  )
}

export const requiredCappedString255 = getRequiredCappedString(255)
export const cappedString255 = getOptionalCappedString(255)
export const cappedString64 = getOptionalCappedString(64)
export const requiredCappedString64 = getRequiredCappedString(64)
export const text = z.preprocess(
  (v) => (typeof v === 'number' ? String(v) : v),
  z.string().transform((val) => (typeof val === 'string' && val ? unescape(sanitize(val)) : val))
)

export const multiLineText = z.preprocess(
  (v) => {
    const valueType = typeof v
    if (valueType === 'string') return v
    if (valueType === 'number') return String(v)
    if (isTextObject(v)) {
      const firstValue = v?.[0]
      const secondValue = v?.[1]
      if (
        firstValue &&
        secondValue &&
        typeof firstValue === 'string' &&
        typeof secondValue === 'string' &&
        firstValue.replace(/\r/g, '') === secondValue.replace(/\r/g, '')
      ) {
        return firstValue.replace(/\r/g, '')
      }
    }
    return v
  },
  z.string().transform((val) => (typeof val === 'string' && val ? unescape(sanitize(val)) : val))
)
