/* eslint-disable import/exports-last */
import votingFields from './votingFields'
import { DonationIntervals, PaymentMethods, ReceiverTypes } from '@betterplace/api-graphql-types'
import { bottomLogoOptions } from '@/donationForm/_dependencies/operations/getDonationFormConfig/getDonationFormConfigSchema'
import { getZipCodeSchema, htmlColorString, numericString } from '@/schema'

import {
  cappedString255,
  cappedString64,
  multiLineText,
  requiredCappedString255,
  requiredCappedString64,
} from '@/schema/sanitizedText'
import { videoFields } from './videoFields'
import { z } from 'zod'
import type { Pretty } from '@/types'
export const MAX_AMOUNT_CENTS = 2000000

export const urlOverrides = z.object({
  background_color: htmlColorString.optional(),
  bottom_logo: bottomLogoOptions,
  color: htmlColorString.optional(),
  client_reference: cappedString64,
  need_id: numericString.optional(),
})

export const base = z
  .object({
    allow_data_transfer: z.boolean(),
    amount_cents: z.coerce.number().int().min(100).max(MAX_AMOUNT_CENTS),
    codonation_cents: z.coerce.number().int().min(0),
    default_amount_cents: z.number().int(),
    default_codonation_cents: z.number().int().optional().or(z.null()),
    display_name: cappedString255,
    email: z.string().min(1).email().max(255),
    first_name: requiredCappedString255,
    interval: z.nativeEnum(DonationIntervals),
    last_name: requiredCappedString255,
    message: multiLineText.optional(),
    newsletter: z.boolean(),
    payment_method: z.nativeEnum(PaymentMethods),
    receipt_desired: z.boolean(),
    receiver_id: numericString,
    receiver_type: z.nativeEnum(ReceiverTypes),
    show_amount: z.boolean(),
    show_codonation: z.boolean(),
    show_user: z.boolean(),
    stripe_token: z.string().optional(),
    tracking_via: cappedString64,
    _company_donation: z.boolean(),
    _step: z.number().int(),
    _custom_amount: z.boolean(),
  })
  .merge(urlOverrides)
  .superRefine(({ codonation_cents, amount_cents }, ctx) => {
    if (codonation_cents > amount_cents) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: amount_cents,
        inclusive: true,
        path: ['codonation_cents'],
        type: 'number',
      })
    }
  })

const zipCodePart = getZipCodeSchema({ countryAlpha2Key: 'country_alpha2', zipCodeKey: 'zip' })
const zipCodePartOptional = getZipCodeSchema({ countryAlpha2Key: 'country_alpha2', zipCodeKey: 'zip', optional: true })

export const addressPart = z.union([
  z
    .object({
      receipt_desired: z.literal(false),
      city: cappedString255,
      // ReactAutosuggest wants street to be always a string
      street: requiredCappedString255.or(z.literal('')),
    })
    .and(zipCodePartOptional),
  z
    .object({
      receipt_desired: z.literal(true),
      city: requiredCappedString255,
      street: requiredCappedString255,
    })
    .and(zipCodePart),
])

export const companyPart = z.discriminatedUnion('_company_donation', [
  z.object({
    company_name: cappedString255,
    _company_donation: z.literal(false),
  }),
  z.object({
    company_name: requiredCappedString255,
    _company_donation: z.literal(true),
  }),
])

const notDirectDeposit = z.custom<Exclude<PaymentMethods, PaymentMethods.DirectDeposit>>(
  (value) => value !== PaymentMethods.DirectDeposit
)

export const paymentMethodAndInterval = z.discriminatedUnion('interval', [
  z.object({
    interval: z.literal(DonationIntervals.Single),
    payment_method: z.nativeEnum(PaymentMethods),
  }),
  z.object({
    interval: z.literal(DonationIntervals.Monthly),
    payment_method: notDirectDeposit,
  }),
  z.object({
    interval: z.literal(DonationIntervals.Yearly),
    payment_method: notDirectDeposit,
  }),
])

// TODO: Make the whole captcha validation a part of an async validator
export const captchaPart = z.union([
  z.object({
    channel: z.literal('iframe'),
    captcha_solution: z.string().optional(),
    _captcha_error: z.string().optional(),
    _captcha_complete: z.boolean(),
  }),
  z.object({
    captcha_solution: z.string().min(1),
    channel: requiredCappedString64,
    _captcha_error: z.string().optional(),
    _captcha_complete: z.literal(true),
  }),
])

const donationFormSchema = base
  .and(paymentMethodAndInterval)
  .and(addressPart)
  .and(companyPart)
  .and(captchaPart)
  .and(videoFields)
  .and(votingFields)

export type DonationFormValues = Pretty<z.infer<typeof donationFormSchema>>
export default donationFormSchema
