import { formatCents } from '@betterplace/utils'
import { getHHmmssTimestampFromSeconds } from '@/helpers/utils'
import { getTranslatorWithFallback } from '@/i18n'
import type { GetErrorMap } from '@/helpers/operations'

const getDonationFormErrorMap: GetErrorMap = (t_, locale) => {
  const t = getTranslatorWithFallback(t_)
  return (issue, ctx) => {
    try {
      if (issue.path.includes('_captcha_complete') || issue.path.includes('captcha_solution')) {
        return { message: t('errors.messages.invalid_captcha') }
      }
      if (issue.path.includes('amount_cents')) {
        if (issue.code === 'too_small') {
          return { message: t('donate.errors.greater_than') }
        }
        if (issue.code === 'too_big') {
          return { message: t('donate.errors.exceeds_platform_limit') }
        }
      }
      if (issue.path.includes('codonation_cents')) {
        if (issue.code === 'too_big') {
          return {
            message: t('errors.messages.number.too_big_inclusive', {
              constraint: formatCents(locale, issue.maximum as number),
            }),
          }
        }
      }
      if (issue.path.includes('video_start_time')) {
        if (issue.code === 'too_big') {
          return {
            message: t('errors.messages.number.too_big', {
              constraint: getHHmmssTimestampFromSeconds(issue.maximum as number),
            }),
          }
        }
      }
      if (issue.path.includes('voting_option_id')) {
        if (issue.code === 'invalid_type') {
          return {
            message: t('donate.errors.voting_missing'),
          }
        }
      }
      if (issue.code === 'too_big' && issue.type === 'string') {
        return { message: t('donate.errors.too_long') }
      }
      return {
        message: ctx.defaultError,
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
      console.error('Error localising validation', (e as any).message)
      return {
        message: ctx.defaultError,
      }
    }
  }
}

export default getDonationFormErrorMap
