import { j as p, T as gt, c as I, s as yt, B as wt, g as xt, l as Le, I as We, w as Fe, a as Ye, S as vt, h as Tt, b as Xe, H as _t, i as Ct, k as St } from "./ToggleSwitch-CJ96GY_z.mjs";
import U, { useRef as D, useCallback as T, forwardRef as G, useMemo as R, useState as K, useEffect as E, createContext as Ge, useContext as Je, useId as ge, useLayoutEffect as Qe, createElement as At, memo as kt } from "react";
import { createPortal as Nt } from "react-dom";
const Et = (e, t) => Number(e === "en" ? String(t).replace(/[^0-9.]/g, "") : String(t).replace(/[^0-9,]/g, "").replace(/,/g, ".")), De = {}, It = (e, t, r, n) => {
  const o = `${e}-${t ? "currency" : "noCurrency"}-${r}-${n}`;
  let i = De[o];
  return i || (i = new Intl.NumberFormat(e ?? "de", {
    minimumFractionDigits: r,
    maximumFractionDigits: r,
    ...t ? { style: "currency", currency: "EUR" } : {},
    ...n ? {} : { useGrouping: !1 }
  }), De[o] = i), i;
};
function _e(e, t, r = {}) {
  const n = (r == null ? void 0 : r.precision) === void 0 ? 2 : r == null ? void 0 : r.precision, a = typeof t == "number" ? t || 0 : Et(e, t), o = (r == null ? void 0 : r.thousandSeparator) ?? !0;
  return It(e, !(r != null && r.format), n, o).format(a).replace(/\u00A0/, " ");
}
function Oe(e) {
  if (!e)
    return 0;
  const t = e.replace(/,/g, "."), r = parseFloat(t) || 0;
  return Math.round(r * 100);
}
function me(e, t = 2) {
  return e.replace(/[^\d.,]/g, "").replace(/^([.,]{1,1})/, "0$1").replace(/^0{2,}/, "0").replace(/^0([\d]+)([\d,.]*)/, "$1$2").replace(/[.,]([^.,]*)(?=[.,])/g, "$1").replace(new RegExp(`([.,]\\d{${t}}).*$`), "$1");
}
function Ze(e) {
  const t = D(null), r = T(
    (n) => {
      if (t.current = n, !!e) {
        if (typeof e == "function") return e(n);
        e.current = n;
      }
    },
    [e]
  );
  return [t, r];
}
function Be(e) {
  if (typeof e > "u") return;
  let t;
  if (typeof e != "number" ? t = parseFloat(e) : t = e, !isNaN(t))
    return Math.round(t * 100);
}
const Pt = G(
  ({
    label: e,
    locale: t = "de",
    name: r,
    id: n,
    min: a = 0,
    onChange: o,
    onBlur: i,
    onFocus: c,
    defaultValue: l,
    value: s,
    step: d,
    children: y,
    className: b,
    hideSteps: m,
    max: x,
    ..._
  }, g) => {
    const f = R(() => jt(d), [d]), [C, A] = K(
      me(
        _e(t, s ?? l ?? "", { precision: f, format: "%v", thousandSeparator: !1 }),
        f
      )
    ), L = R(() => Be(x), [x]), P = R(() => Be(a), [a]), [F, O] = K(null), [u, h] = Ze(g), j = T(
      (v) => {
        const k = ze(v.target.value, t, f), Y = Oe(k);
        if (typeof L < "u" && Y > L || typeof P < "u" && Y < P) {
          const ue = v.target.selectionStart ? v.target.selectionStart : 0;
          return O(ue), v.preventDefault();
        }
        O(v.target.selectionStart);
        let V = v.target.value;
        k.startsWith(v.target.value) || (V = k);
        const se = Oe(v.target.value);
        v.target.value && !isNaN(se) && Y === se && (o == null || o(v)), A(V);
      },
      [t, f, P, L, o]
    ), S = T(
      (v) => {
        const k = ze(v.target.value, t, f);
        k !== v.target.value && (v.target.value = k), O(null), i == null || i(v);
      },
      [i, f, t]
    ), $ = T(
      (v) => {
        O(v.target.selectionStart), c == null || c(v);
      },
      [c]
    ), w = R(
      () => l ? { defaultValue: C } : { value: C },
      [l, C]
    );
    return E(() => {
      if (typeof l < "u") return;
      const v = s ?? l;
      if (typeof s > "u")
        return A("");
      const k = me(
        _e(t, v, { precision: f, format: "%v", thousandSeparator: !1 }),
        f
      );
      A(k);
    }, [s, f, l, t]), E(() => {
      const v = (C == null ? void 0 : C.length) ?? 0, k = (F ?? 0) > v ? v : F;
      F === null || !u.current || document.activeElement !== u.current || u.current.setSelectionRange(k, k);
    }, [F, u, C]), /* @__PURE__ */ p.jsx(
      gt,
      {
        ..._,
        ...w,
        onFocus: $,
        onBlur: S,
        onChange: j,
        step: d,
        ref: h,
        id: n || r,
        name: r,
        label: e,
        pattern: "[0-9]*[.,]?[0-9]*",
        type: "text",
        inputMode: "decimal",
        className: I(b, { [yt.hideSteps]: m }),
        children: y
      }
    );
  }
);
Pt.displayName = "ClientAmountField";
function ze(e, t, r = 2) {
  if (typeof e > "u" || e === null)
    return e;
  const n = String(e), a = me(n, r), o = _e(t, a, { precision: r, format: "%v", thousandSeparator: !1 }), i = me(o, r);
  if (!r)
    return i;
  const c = i.search(/[.,]/);
  if (c < 0)
    return i;
  const l = r - (i.length - 1 - c);
  return i.concat("0".repeat(l));
}
function jt(e) {
  var r;
  return e === void 0 ? void 0 : ((r = String(e).split(".")[1]) == null ? void 0 : r.length) ?? 0;
}
function M(e) {
  e.preventDefault(), e.stopPropagation();
}
const Mt = G(function({
  onKeyDown: t,
  onKeyUp: r,
  onKeyDownCapture: n,
  onKeyUpCapture: a,
  onClick: o,
  ...i
}, c) {
  const { disabled: l, busy: s } = i, d = l || s, y = d ? M : t, b = d ? M : r, m = d ? M : n, x = d ? M : a, _ = d ? M : o;
  return /* @__PURE__ */ p.jsx(
    wt,
    {
      ...i,
      onClick: _,
      onChange: y,
      onKeyUp: b,
      onKeyDownCapture: m,
      onKeyUpCapture: x,
      ref: c
    }
  );
}), mn = G(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, i = o ? M : t, c = o ? M : r;
  return /* @__PURE__ */ p.jsx(xt, { ...n, onChange: i, onClick: c, ref: a });
}), Lt = "_tooltipArrow_qcdrl_1", Ft = "_bottomTooltipArrow_qcdrl_32", Dt = "_topTooltipArrow_qcdrl_37", xe = {
  tooltipArrow: Lt,
  bottomTooltipArrow: Ft,
  topTooltipArrow: Dt
}, H = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right"
}, Q = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
  JUSTIFY: "justify"
};
function Ot({ x: e, position: t, className: r }) {
  return /* @__PURE__ */ p.jsx(
    "div",
    {
      className: I(xe.tooltipArrow, r, {
        [xe.bottomTooltipArrow]: t === H.BOTTOM,
        [xe.topTooltipArrow]: t === H.TOP
      }),
      style: { transform: `translateX(${e}px) ${t === H.BOTTOM ? " rotate(180deg)" : ""}` }
    }
  );
}
const Bt = "_picker_yqwd0_1", zt = "_swatch_yqwd0_7", $t = "_activeSwatch_yqwd0_19", Kt = "_inactiveSwatch_yqwd0_25", Ht = "_errorSwatch_yqwd0_30", Ut = "_swatchInner_yqwd0_34", qt = "_activePickerSwatch_yqwd0_41", Rt = "_dropdown_yqwd0_45", Wt = "_popover_yqwd0_49", Yt = "_pickerSwatches_yqwd0_55", Xt = "_input_yqwd0_68", Gt = "_label_yqwd0_91", X = {
  picker: Bt,
  swatch: zt,
  activeSwatch: $t,
  inactiveSwatch: Kt,
  errorSwatch: Ht,
  swatchInner: Ut,
  activePickerSwatch: qt,
  dropdown: Rt,
  popover: Wt,
  pickerSwatches: Yt,
  input: Xt,
  label: Gt
}, Jt = "_dropdownContent_1n9sv_1", Qt = "_dropdownTriggerButton_1n9sv_14", Zt = "_scaleTransition_1n9sv_26", Vt = "_scaleTransitionOpen_1n9sv_34", er = "_dropdownMenu_1n9sv_39", tr = "_dropdownMenuSeparator_1n9sv_50", rr = "_dropdownMenuItem_1n9sv_59", ne = {
  dropdownContent: Jt,
  dropdownTriggerButton: Qt,
  scaleTransition: Zt,
  scaleTransitionOpen: Vt,
  dropdownMenu: er,
  dropdownMenuSeparator: tr,
  dropdownMenuItem: rr
}, nr = "_popupContainer_y7ppu_1", or = "_spyElement_y7ppu_15", ar = "_nonFocusableOutline_y7ppu_21", Ee = {
  popupContainer: nr,
  spyElement: or,
  nonFocusableOutline: ar
}, cr = {
  a: "link",
  area: "link",
  article: "article",
  aside: "complementary",
  button: "button",
  details: "group",
  dfn: "term",
  dialog: "dialog",
  fieldset: "group",
  figure: "figure",
  footer: "contentinfo",
  form: "form",
  h1: "heading",
  h2: "heading",
  h3: "heading",
  h4: "heading",
  h5: "heading",
  h6: "heading",
  header: "banner",
  hr: "separator",
  iframe: "document",
  img: "img",
  input: null,
  // for type purposes this value needs to be included
  li: "listitem",
  main: "main",
  menu: "list",
  menuitem: "menuitem",
  meter: "meter",
  nav: "navigation",
  ol: "list",
  option: "option",
  progress: "progressbar",
  section: "region",
  select: "listbox",
  summary: "button",
  table: "table",
  tbody: "rowgroup",
  td: "cell",
  textarea: "textbox",
  tfoot: "rowgroup",
  th: "columnheader",
  thead: "rowgroup",
  tr: "row",
  ul: "list"
}, ir = {
  button: "button",
  checkbox: "checkbox",
  radio: "radio",
  range: "slider",
  text: "textbox",
  password: "textbox",
  email: "textbox",
  search: "searchbox",
  number: "spinbutton",
  tel: "textbox",
  url: "textbox",
  submit: "button",
  reset: "button",
  image: "button",
  file: "textbox"
}, Z = ["a", "button", "input", "textarea", "select", "details", "area", "label"];
function lr(e) {
  return !e || !e.trim() ? /* @__PURE__ */ new Set() : new Set(e.trim().split(/\s+/));
}
function sr(e, t) {
  return [...lr(e).add(t)].join(" ");
}
function ur(e) {
  const t = e.getAttribute("role");
  if (t) return t;
  const r = e.tagName.toLowerCase();
  if (r === "input") {
    const a = e.getAttribute("type");
    return a ? ir[a] : "textbox";
  }
  const n = cr[r];
  return n || "button";
}
function Ve(e, t = Z) {
  if (!(!e || !(e instanceof HTMLElement)))
    return e.querySelectorAll(`${t.join(",")},[tabindex]:not([tabindex="-1"])`);
}
function et(e, t = Z) {
  if (!e) return !1;
  const r = e.getAttribute("tabindex");
  if (!r && !t.includes(e.tagName.toLowerCase()) || r && parseInt(r) < 0 || e.hasAttribute("disabled")) return !1;
  const a = window.getComputedStyle(e);
  return !(a.display === "none" || a.visibility === "hidden");
}
function ye(e, t = Z) {
  if (!e || !(e instanceof HTMLElement)) return null;
  const r = e.querySelectorAll(
    `${t.join(",")}:not([tabindex="-1"]):not([disabled]):not([inert]),[tabindex]:not([tabindex="-1"]):not([disabled]):not([inert])`
  );
  for (const n of r)
    if (et(n, t))
      return n;
  return null;
}
function Ie(e, t = Z) {
  return !e || !(e instanceof HTMLElement) ? null : et(e, t) ? e : ye(e, t);
}
function tt(e, t, r = Z) {
  if (!t || !(t instanceof HTMLElement)) return null;
  let n = t, a = null;
  for (; (n = e(n)) && !(a = Ie(n, r)); )
    ;
  return a;
}
function rt(e, t = Z) {
  return tt((r) => (r == null ? void 0 : r.nextElementSibling) ?? null, e, t);
}
function dr(e, t = Z) {
  return tt((r) => (r == null ? void 0 : r.previousElementSibling) ?? null, e, t);
}
function nt(e, t = Z) {
  if (!e || !(e instanceof HTMLElement)) return null;
  let r = e;
  for (; ; ) {
    const n = rt(r, t);
    if (n) return n;
    if (r = (r == null ? void 0 : r.parentElement) ?? null, !r || r === e) return null;
  }
}
async function pr() {
  return new Promise((e) => {
    setTimeout(() => window.requestAnimationFrame(() => e()));
  });
}
const Pe = "data-popup", Ce = "data-popup-id";
function $e(e) {
  let t = e;
  for (; t; ) {
    if (t != null && t.hasAttribute(Pe)) return t;
    t = (t == null ? void 0 : t.parentElement) ?? null;
  }
  return null;
}
function fr(e, t) {
  if (!t || !e) return !1;
  const r = $e(t);
  if (!r) return !1;
  const n = r.getAttribute(Pe);
  if (!n) return !1;
  if (e.getAttribute(Ce) === n) return !0;
  const a = $e(e);
  return a ? !!a.querySelector(`[${Ce}="${n}"]`) : !1;
}
function ce(e) {
  return e ? e.clientHeight && e.scrollHeight > e.clientHeight || e.tagName === "DIALOG" ? e.tagName === "HTML" ? window : e : ce(e.parentNode) : null;
}
const ot = Ge({
  initialised: !1,
  open: !1,
  inDOM: !1,
  setInDOM: () => {
  },
  showPopup: () => {
  },
  hidePopup: () => {
  },
  togglePopup: () => {
  },
  hasClickTrigger: !1,
  hasKeypressTrigger: !1,
  hasFocusTrigger: !1,
  hasHoverTrigger: !1
});
function ie() {
  return Je(ot);
}
function mr(e, t) {
  return JSON.stringify(e) === JSON.stringify(t);
}
function at(e) {
  return (t) => t === e || t && mr(t, e) ? t : e;
}
const z = {
  Enter: "Enter",
  Space: " ",
  Tab: "Tab",
  Escape: "Escape",
  Down: "ArrowDown",
  Up: "ArrowUp",
  Left: "ArrowLeft",
  Right: "ArrowRight"
};
function be() {
  const e = document.getElementById("bp-portal-target");
  if (e) return e;
  const t = document.createElement("div");
  return t.id = "bp-portal-target", t.style.position = "absolute", t.setAttribute("role", "popup"), document.body.appendChild(t), t;
}
function br(e) {
  if (!e) return null;
  const { height: t, width: r } = e;
  return {
    height: t,
    width: r
  };
}
function hr({ triggerRect: e, id: t, onCloseTransitionEnd: r, onPopupLeave: n, children: a }) {
  const {
    popupRef: o,
    spyRef: i,
    preferredPopupPosition: c,
    alignPopup: l,
    hasHoverTrigger: s,
    Anchor: d,
    contentClassName: y,
    contentWrapperClassName: b,
    popupOpenWrapperClassName: m,
    anchorClassName: x
  } = ie(), [_, g] = K(null), [f, C] = K(null), [A, L] = K(null), [P, F] = K(!1), { open: O, hidePopup: u, popupRef: h } = ie();
  E(() => {
    if (!O) return F(!1);
    const N = window.requestAnimationFrame(() => {
      F(!0);
    });
    return () => window.cancelAnimationFrame(N);
  }, [O, F]);
  const j = T(() => {
    var B;
    const N = br((B = o == null ? void 0 : o.current) == null ? void 0 : B.getBoundingClientRect());
    g(at(N));
  }, [o]), S = T(() => {
    const N = ce(be()) ?? document.body, W = (N === window ? document.body : N).getBoundingClientRect();
    C(W.width), L(W.height);
  }, []), $ = T(
    (N) => {
      var W;
      if (N.key !== z.Escape) return;
      N.preventDefault();
      const B = (W = i == null ? void 0 : i.current) == null ? void 0 : W.nextElementSibling;
      B == null || B.focus(), u();
    },
    [u, i]
  );
  E(() => {
    const N = o == null ? void 0 : o.current;
    if (!N) return;
    const B = Ve(N);
    return B == null || B.forEach((W, pe) => {
      W.addEventListener("keydown", $);
    }), () => {
      B == null || B.forEach((W, pe) => {
        W.removeEventListener("keydown", $);
      });
    };
  }, [a, $, o]), Qe(() => {
    j(), S();
  }, [j, S]);
  const w = T(() => {
    window.requestAnimationFrame(() => {
      j(), S();
    });
  }, [j, S]), v = T(
    (N) => {
      s && n(N);
    },
    [n, s]
  ), k = T(
    (N) => {
      var W, pe, Me;
      const B = (pe = (W = h == null ? void 0 : h.current) == null ? void 0 : W.firstElementChild) == null ? void 0 : pe.firstElementChild;
      if (N.target !== B && !((Me = o == null ? void 0 : o.current) != null && Me.contains(N.relatedTarget))) {
        if (fr(B, N.relatedTarget))
          return N.preventDefault();
        n(N);
      }
    },
    [n, h, o]
  );
  E(() => (window.addEventListener("resize", w), () => {
    window.removeEventListener("resize", w);
  }), [w]);
  const Y = R(() => d ? 17 : 0, [d]);
  E(() => {
    if (O) return;
    const N = o == null ? void 0 : o.current;
    if (!m) return r();
    N == null || N.addEventListener("transitionend", r);
    const B = setTimeout(r, 300);
    return () => {
      clearTimeout(B), N == null || N.removeEventListener("transitionend", r);
    };
  }, [o, O, r, m]);
  const {
    x: V,
    y: se,
    anchorOffsetX: ue,
    position: de,
    width: ht
  } = R(
    () => vr({
      triggerRect: e,
      popupSize: _,
      bodyWidth: f,
      bodyHeight: A,
      anchorSize: Y,
      preferredPopupPosition: c,
      alignPopup: l
    }),
    [e, _, f, A, Y, c, l]
  );
  return Nt(
    /* @__PURE__ */ p.jsx(
      "div",
      {
        id: t,
        className: Ee.popupContainer,
        ref: o,
        onPointerLeave: v,
        onBlur: k,
        style: {
          transform: `translate3d(${V}px, ${se}px, 0)`
        },
        children: /* @__PURE__ */ p.jsxs(
          "div",
          {
            className: I(
              b,
              m && { [m]: P }
            ),
            children: [
              d && de === H.BOTTOM && /* @__PURE__ */ p.jsx(d, { position: de, x: ue, className: x }),
              /* @__PURE__ */ p.jsx(
                "div",
                {
                  tabIndex: -1,
                  className: y,
                  style: {
                    ...l === Q.JUSTIFY ? { minWidth: ht, overflowX: "hidden" } : {}
                  },
                  [Pe]: t,
                  children: a
                }
              ),
              d && de === H.TOP && /* @__PURE__ */ p.jsx(d, { position: de, x: ue, className: x })
            ]
          }
        )
      }
    ),
    be()
  );
}
function gr(e, t, r) {
  E(() => {
    var d;
    const n = (d = r == null ? void 0 : r.current) == null ? void 0 : d.nextElementSibling;
    if (!n) return;
    const a = ye(n), o = a ?? n;
    n.setAttribute(Ce, e);
    const c = o.getAttribute("aria-roledescription") ?? ur(o) ?? "button";
    o.setAttribute("role", "button"), a || (o == null || o.setAttribute("tabindex", "0"), o == null || o.classList.add(Ee.nonFocusableOutline)), o.setAttribute("aria-roledescription", c);
    const l = o.getAttribute("aria-describedby");
    (l == null ? void 0 : l.includes(e)) || o.setAttribute("aria-describedby", sr(l, e));
  }, [t, e, r]);
}
function yr(e, t, r) {
  if (!e) return null;
  const { left: n, top: a, bottom: o, width: i } = e;
  return {
    left: n,
    top: a,
    bottom: o,
    width: i,
    offsetX: r,
    offsetY: t
  };
}
function wr({ children: e, content: t, id: r }) {
  const {
    showPopup: n,
    hidePopup: a,
    togglePopup: o,
    open: i,
    spyRef: c,
    popupRef: l,
    hasClickTrigger: s,
    hasFocusTrigger: d,
    hasHoverTrigger: y,
    hasKeypressTrigger: b,
    setInDOM: m
  } = ie(), [x, _] = K(null), g = R(() => !!x, [x]);
  gr(r, e, c);
  const f = T(() => {
    var v;
    const u = (v = c == null ? void 0 : c.current) == null ? void 0 : v.nextSibling;
    let h = u == null ? void 0 : u.getBoundingClientRect();
    h != null && h.height || (h = void 0);
    let j = 0, S = 0;
    const $ = ce(be()) ?? window;
    if ($ !== window) {
      const k = $;
      j = -k.getBoundingClientRect().top + k.scrollTop, S = -k.getBoundingClientRect().left + k.scrollLeft;
    }
    const w = yr(h, j, S);
    _(at(w)), m(!0);
  }, [m, c]), C = T(() => {
    _(null), m(!1);
  }, [m]), A = T(() => {
    g && f();
  }, [f, g]), L = T(
    (u) => {
      const h = l == null ? void 0 : l.current;
      if (u.relatedTarget instanceof Node && (h != null && h.contains(u.relatedTarget)))
        return u.preventDefault();
      a();
    },
    [a, l]
  ), P = T(
    (u) => {
      var h, j;
      if (u.relatedTarget instanceof Node && ((j = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextSibling) != null && j.contains(u.relatedTarget)))
        return u.preventDefault();
      a();
    },
    [a, c]
  ), F = T(
    (u) => {
      var j, S, $;
      const h = u.target instanceof Node;
      h && ((j = l == null ? void 0 : l.current) != null && j.contains(u.target)) || h && (($ = (S = c == null ? void 0 : c.current) == null ? void 0 : S.nextElementSibling) != null && $.contains(u.target)) || P(u);
    },
    [P, l, c]
  ), O = T(
    (u) => {
      if (u.key === z.Enter || u.key === z.Space)
        return u.preventDefault(), o();
      if (u.key === z.Escape) return a();
    },
    [a, o]
  );
  return E(() => {
    if (s)
      return document.addEventListener("pointerdown", F), () => {
        document.removeEventListener("pointerdown", F);
      };
  }, [s, F]), E(() => {
    var h;
    if (!s) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("pointerdown", o), () => {
      u == null || u.removeEventListener("pointerdown", o);
    };
  }, [s, o, c, e]), E(() => {
    var h;
    if (!y) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("mouseover", n), u == null || u.addEventListener("mouseleave", L), () => {
      u == null || u.removeEventListener("mouseover", n), u == null || u.removeEventListener("mouseleave", L);
    };
  }, [y, L, n, c, e]), E(() => {
    var h;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("focusout", L), () => u == null ? void 0 : u.removeEventListener("focusout", L);
  }, [L, c, e]), E(() => {
    var h;
    if (!d) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("focus", n), u == null || u.addEventListener("focusin", n), () => {
      u == null || u.removeEventListener("focus", n), u == null || u.removeEventListener("focusin", n);
    };
  }, [d, n, e, c]), E(() => {
    var h;
    if (!b) return;
    const u = (h = c == null ? void 0 : c.current) == null ? void 0 : h.nextElementSibling;
    return u == null || u.addEventListener("keydown", O), () => {
      u == null || u.removeEventListener("keydown", O);
    };
  }, [b, O, c, e]), E(() => {
    if (!i) return;
    window.addEventListener("resize", A, { passive: !0 });
    const u = ce(be()) ?? window;
    return u.addEventListener("scroll", A, { passive: !0 }), () => {
      window.removeEventListener("resize", A), u.removeEventListener("scroll", A);
    };
  }, [i, A]), Qe(() => {
    A();
  }, [e, A]), E(() => {
    i && f();
  }, [i, f]), /* @__PURE__ */ p.jsxs(p.Fragment, { children: [
    /* @__PURE__ */ p.jsx("div", { ref: c, className: Ee.spyElement }),
    e,
    g && /* @__PURE__ */ p.jsx(
      hr,
      {
        triggerRect: x,
        onCloseTransitionEnd: C,
        id: r,
        onPopupLeave: P,
        children: t
      }
    )
  ] });
}
function xr({
  children: e,
  content: t,
  id: r,
  triggerEvents: n = ["hover", "focus"],
  inactive: a,
  ...o
}) {
  const [i, c] = K(!1), [l, s] = K(!1), [d, y] = K(!1), b = D(null), m = D(null), x = ie(), _ = ge(), g = R(() => r ?? _, [r]), f = R(() => n == null ? void 0 : n.includes("click"), [n]), C = R(() => n == null ? void 0 : n.includes("focus"), [n]), A = R(() => n == null ? void 0 : n.includes("hover"), [n]), L = R(() => n == null ? void 0 : n.includes("keypress"), [n]), P = T(() => {
    s(!1);
  }, []), F = T(() => {
    s(!0);
  }, []), O = T(() => {
    s((u) => !u);
  }, []);
  return E(() => (c(!0), () => {
    c(!1);
  }), []), !i || a ? e : /* @__PURE__ */ p.jsx(
    ot.Provider,
    {
      value: {
        open: l,
        showPopup: F,
        hidePopup: P,
        togglePopup: O,
        spyRef: m,
        popupRef: b,
        hasClickTrigger: f,
        hasHoverTrigger: A,
        hasFocusTrigger: C,
        hasKeypressTrigger: L,
        inDOM: d,
        setInDOM: y,
        initialised: !0,
        parentContext: x,
        ...o
      },
      children: /* @__PURE__ */ p.jsx(wr, { content: t, id: g, children: e })
    }
  );
}
function vr({
  triggerRect: e,
  popupSize: t,
  bodyWidth: r,
  bodyHeight: n,
  originalMargin: a = 20,
  anchorSize: o = 17,
  preferredPopupPosition: i = H.TOP,
  alignPopup: c = Q.CENTER
}) {
  let l = 0, s = 0, d = 0, y = 0, b = i, m = (t == null ? void 0 : t.width) ?? 0;
  if (!e || !t || !r || !n)
    return { x: l, y: s, anchorOffsetX: y, position: b, width: m };
  let x = 0, _ = t.width / 2;
  const g = e.width / 2;
  c === Q.CENTER ? l = e.left + e.offsetX - _ + g : c === Q.LEFT || c === Q.JUSTIFY ? (l = e.left + e.offsetX, c === Q.JUSTIFY && (m = e.width, _ = g)) : c === Q.RIGHT && (l = e.left + e.offsetX + e.width - m);
  const f = t.width > r ? 0 : Math.min(a, (r - m) / 2);
  if (x = l, d = l + t.width, l < f ? l = f : d > r - f && (l -= d - (r - f)), o) {
    y = x - l + _ - o;
    const C = y + 2 * o - m;
    if (C > 0) {
      const A = Math.min(C, f);
      l += A, y -= A;
    }
  }
  return i === H.TOP ? (s = e.top + e.offsetY - t.height, s < Math.abs(e.offsetY) && (b = H.BOTTOM, s = e.bottom)) : (s = e.bottom + e.offsetY, s + t.height > n + e.offsetY && (b = H.TOP, s = e.top + e.offsetY - t.height)), { x: l, y: s, anchorOffsetX: y, position: b, width: m };
}
const Tr = ["click", "keypress"];
function bn({
  children: e,
  style: t,
  className: r,
  id: n
}) {
  return /* @__PURE__ */ p.jsxs(
    "button",
    {
      id: n,
      style: t,
      className: I(Le.selectContainer, Le.select, ne.dropdownTriggerButton, r),
      type: "button",
      children: [
        e,
        /* @__PURE__ */ p.jsx(We, { name: "arrowDown" })
      ]
    }
  );
}
function hn({
  children: e,
  className: t,
  required: r,
  hint: n,
  id: a
}) {
  return /* @__PURE__ */ p.jsx("div", { children: e ? /* @__PURE__ */ p.jsxs("label", { htmlFor: a, className: I(Fe.label, t), children: [
    /* @__PURE__ */ p.jsxs("div", { children: [
      e,
      r && /* @__PURE__ */ p.jsx("span", { "aria-label": "(required)", children: "*" })
    ] }),
    n ? /* @__PURE__ */ p.jsx("div", { className: I(Fe.hint), children: n }) : null
  ] }) : null });
}
function gn({ children: e, className: t }) {
  return /* @__PURE__ */ p.jsx("ul", { className: I(ne.dropdownMenu, t), children: e });
}
function yn({
  children: e,
  style: t,
  className: r
}) {
  const n = D(null), { preferredPopupPosition: a, hidePopup: o, parentContext: i, spyRef: c } = oe(), l = T(
    (s) => {
      var b, m;
      const d = i && a, y = d && ct[d].close;
      if (s.key === z.Down || s.key === z.Up) {
        s.preventDefault();
        const x = s.key === z.Up ? dr(n.current) : rt(n.current);
        x == null || x.focus();
        return;
      }
      s.key === y && (s.preventDefault(), (m = Ie((b = c == null ? void 0 : c.current) == null ? void 0 : b.nextElementSibling)) == null || m.focus(), o()), s.key === z.Enter && s.target instanceof HTMLElement && "click" in s.target && s.target.click();
    },
    [o, i, a, c]
  );
  return E(() => {
    const s = ye(n.current);
    if (s)
      return s.addEventListener("keydown", l), s.addEventListener("click", o), () => {
        s.removeEventListener("keydown", l), s.removeEventListener("click", o);
      };
  }, [o, l]), /* @__PURE__ */ p.jsx("li", { ref: n, style: t, className: I(ne.dropdownMenuItem, r), children: e });
}
function wn() {
  return /* @__PURE__ */ p.jsx("li", { className: ne.dropdownMenuSeparator });
}
const ct = {
  [H.BOTTOM]: { open: z.Down, close: z.Down },
  [H.TOP]: { open: z.Up, close: z.Down },
  [H.RIGHT]: { open: z.Right, close: z.Left },
  [H.LEFT]: { open: z.Left, close: z.Right }
};
function _r({ children: e }) {
  const { spyRef: t, popupRef: r, showPopup: n, preferredPopupPosition: a } = oe(), o = T(
    (i) => {
      const c = a ?? H.BOTTOM, l = ct[c].open;
      i.key === l && (i.preventDefault(), n(), pr().then(() => {
        var d;
        let s = ye(r == null ? void 0 : r.current);
        if (s) {
          s.focus();
          return;
        }
        i.key === z.Tab && (s = nt((d = t == null ? void 0 : t.current) == null ? void 0 : d.nextElementSibling), s == null || s.focus());
      }));
    },
    [r, a, n, t]
  );
  return E(() => {
    var c;
    const i = (c = t == null ? void 0 : t.current) == null ? void 0 : c.nextElementSibling;
    if (i)
      return i.addEventListener("keydown", o), () => i.removeEventListener("keydown", o);
  }, [o, t]), e;
}
function Cr({ children: e }) {
  const { spyRef: t, popupRef: r, hidePopup: n, open: a } = oe(), o = T(
    (c) => {
      var l, s;
      c.key !== z.Tab || !c.shiftKey || (c.preventDefault(), (s = Ie((l = t == null ? void 0 : t.current) == null ? void 0 : l.nextElementSibling)) == null || s.focus(), n());
    },
    [t, n]
  ), i = T(
    (c) => {
      var s;
      if (c.key !== z.Tab || c.shiftKey) return;
      c.preventDefault();
      const l = nt((s = t == null ? void 0 : t.current) == null ? void 0 : s.nextElementSibling);
      l == null || l.focus();
    },
    [t]
  );
  return E(() => {
    const c = r == null ? void 0 : r.current;
    if (!c) return;
    const l = Ve(c);
    if (!l || !l.length) return;
    const s = l.item(0), d = l.item(l.length - 1);
    return s.addEventListener("keydown", o), d.addEventListener("keydown", i), () => {
      s.removeEventListener("keydown", o), d.removeEventListener("keydown", i);
    };
  }, [o, i, t, r, e, a]), e;
}
function Sr({
  trigger: e,
  children: t,
  className: r,
  alignContent: n = Q.JUSTIFY,
  wrapperClassName: a,
  anchorClassName: o,
  preferredPopupPosition: i,
  Anchor: c
}) {
  const { parentContext: l } = oe(), s = i ?? (l ? l.preferredPopupPosition ?? H.RIGHT : H.BOTTOM);
  return /* @__PURE__ */ p.jsx(
    xr,
    {
      content: /* @__PURE__ */ p.jsx(Cr, { children: t }),
      contentClassName: I(ne.dropdownContent, r),
      contentWrapperClassName: I(ne.scaleTransition, a),
      popupOpenWrapperClassName: ne.scaleTransitionOpen,
      triggerEvents: Tr,
      preferredPopupPosition: s,
      alignPopup: n,
      anchorClassName: o,
      Anchor: c,
      children: /* @__PURE__ */ p.jsx(_r, { children: e })
    }
  );
}
function oe() {
  const {
    showPopup: e,
    hidePopup: t,
    togglePopup: r,
    spyRef: n,
    popupRef: a,
    inDOM: o,
    parentContext: i,
    alignPopup: c,
    preferredPopupPosition: l
  } = ie(), s = i && i.initialised ? i : void 0;
  return {
    open: o,
    showPopup: e,
    hidePopup: t,
    togglePopup: r,
    spyRef: n,
    popupRef: a,
    parentContext: s,
    alignPopup: c,
    preferredPopupPosition: l
  };
}
function we() {
  return (we = Object.assign || function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var r = arguments[t];
      for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
    }
    return e;
  }).apply(this, arguments);
}
function je(e, t) {
  if (e == null) return {};
  var r, n, a = {}, o = Object.keys(e);
  for (n = 0; n < o.length; n++) t.indexOf(r = o[n]) >= 0 || (a[r] = e[r]);
  return a;
}
function he(e) {
  var t = D(e), r = D(function(n) {
    t.current && t.current(n);
  });
  return t.current = e, r.current;
}
var le = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = 1), e > r ? r : e < t ? t : e;
}, ae = function(e) {
  return "touches" in e;
}, Se = function(e) {
  return e && e.ownerDocument.defaultView || self;
}, Ke = function(e, t, r) {
  var n = e.getBoundingClientRect(), a = ae(t) ? function(o, i) {
    for (var c = 0; c < o.length; c++) if (o[c].identifier === i) return o[c];
    return o[0];
  }(t.touches, r) : t;
  return { left: le((a.pageX - (n.left + Se(e).pageXOffset)) / n.width), top: le((a.pageY - (n.top + Se(e).pageYOffset)) / n.height) };
}, He = function(e) {
  !ae(e) && e.preventDefault();
}, it = U.memo(function(e) {
  var t = e.onMove, r = e.onKey, n = je(e, ["onMove", "onKey"]), a = D(null), o = he(t), i = he(r), c = D(null), l = D(!1), s = R(function() {
    var m = function(g) {
      He(g), (ae(g) ? g.touches.length > 0 : g.buttons > 0) && a.current ? o(Ke(a.current, g, c.current)) : _(!1);
    }, x = function() {
      return _(!1);
    };
    function _(g) {
      var f = l.current, C = Se(a.current), A = g ? C.addEventListener : C.removeEventListener;
      A(f ? "touchmove" : "mousemove", m), A(f ? "touchend" : "mouseup", x);
    }
    return [function(g) {
      var f = g.nativeEvent, C = a.current;
      if (C && (He(f), !function(L, P) {
        return P && !ae(L);
      }(f, l.current) && C)) {
        if (ae(f)) {
          l.current = !0;
          var A = f.changedTouches || [];
          A.length && (c.current = A[0].identifier);
        }
        C.focus(), o(Ke(C, f, c.current)), _(!0);
      }
    }, function(g) {
      var f = g.which || g.keyCode;
      f < 37 || f > 40 || (g.preventDefault(), i({ left: f === 39 ? 0.05 : f === 37 ? -0.05 : 0, top: f === 40 ? 0.05 : f === 38 ? -0.05 : 0 }));
    }, _];
  }, [i, o]), d = s[0], y = s[1], b = s[2];
  return E(function() {
    return b;
  }, [b]), U.createElement("div", we({}, n, { onTouchStart: d, onMouseDown: d, className: "react-colorful__interactive", ref: a, onKeyDown: y, tabIndex: 0, role: "slider" }));
}), lt = function(e) {
  return e.filter(Boolean).join(" ");
}, st = function(e) {
  var t = e.color, r = e.left, n = e.top, a = n === void 0 ? 0.5 : n, o = lt(["react-colorful__pointer", e.className]);
  return U.createElement("div", { className: o, style: { top: 100 * a + "%", left: 100 * r + "%" } }, U.createElement("div", { className: "react-colorful__pointer-fill", style: { backgroundColor: t } }));
}, re = function(e, t, r) {
  return t === void 0 && (t = 0), r === void 0 && (r = Math.pow(10, t)), Math.round(r * e) / r;
}, Ar = function(e) {
  var t = e.s, r = e.v, n = e.a, a = (200 - t) * r / 100;
  return { h: re(e.h), s: re(a > 0 && a < 200 ? t * r / 100 / (a <= 100 ? a : 200 - a) * 100 : 0), l: re(a / 2), a: re(n, 2) };
}, Ae = function(e) {
  var t = Ar(e);
  return "hsl(" + t.h + ", " + t.s + "%, " + t.l + "%)";
};
U.memo(function(e) {
  var t = e.hue, r = e.onChange, n = lt(["react-colorful__hue", e.className]);
  return U.createElement("div", { className: n }, U.createElement(it, { onMove: function(a) {
    r({ h: 360 * a.left });
  }, onKey: function(a) {
    r({ h: le(t + 360 * a.left, 0, 360) });
  }, "aria-label": "Hue", "aria-valuenow": re(t), "aria-valuemax": "360", "aria-valuemin": "0" }, U.createElement(st, { className: "react-colorful__hue-pointer", left: t / 360, color: Ae({ h: t, s: 100, v: 100, a: 1 }) })));
});
U.memo(function(e) {
  var t = e.hsva, r = e.onChange, n = { backgroundColor: Ae({ h: t.h, s: 100, v: 100, a: 1 }) };
  return U.createElement("div", { className: "react-colorful__saturation", style: n }, U.createElement(it, { onMove: function(a) {
    r({ s: 100 * a.left, v: 100 - 100 * a.top });
  }, onKey: function(a) {
    r({ s: le(t.s + 100 * a.left, 0, 100), v: le(t.v - 100 * a.top, 0, 100) });
  }, "aria-label": "Color", "aria-valuetext": "Saturation " + re(t.s) + "%, Brightness " + re(t.v) + "%" }, U.createElement(st, { className: "react-colorful__saturation-pointer", top: 1 - t.v / 100, left: t.s / 100, color: Ae(t) })));
});
var kr = /^#?([0-9A-F]{3,8})$/i, Nr = function(e) {
  var t = e.color, r = t === void 0 ? "" : t, n = e.onChange, a = e.onBlur, o = e.escape, i = e.validate, c = e.format, l = e.process, s = je(e, ["color", "onChange", "onBlur", "escape", "validate", "format", "process"]), d = K(function() {
    return o(r);
  }), y = d[0], b = d[1], m = he(n), x = he(a), _ = T(function(f) {
    var C = o(f.target.value);
    b(C), i(C) && m(l ? l(C) : C);
  }, [o, l, i, m]), g = T(function(f) {
    i(f.target.value) || b(o(r)), x(f);
  }, [r, o, i, x]);
  return E(function() {
    b(o(r));
  }, [r, o]), U.createElement("input", we({}, s, { value: c ? c(y) : y, spellCheck: "false", onChange: _, onBlur: g }));
}, Ue = function(e) {
  return "#" + e;
}, Er = function(e) {
  var t = e.prefixed, r = e.alpha, n = je(e, ["prefixed", "alpha"]), a = T(function(i) {
    return i.replace(/([^0-9A-F]+)/gi, "").substring(0, r ? 8 : 6);
  }, [r]), o = T(function(i) {
    return function(c, l) {
      var s = kr.exec(c), d = s ? s[1].length : 0;
      return d === 3 || d === 6 || !!l && d === 4 || !!l && d === 8;
    }(i, r);
  }, [r]);
  return U.createElement(Nr, we({}, n, { escape: a, format: t ? Ue : void 0, process: Ue, validate: o }));
};
const Ir = {
  "betterplace-dimension-0": "1rem",
  "betterplace-dimension-1": "1.25rem",
  "betterplace-dimension-2": "1.5rem",
  "betterplace-dimension-3": "2rem",
  "betterplace-dimension-4": "3rem",
  "betterplace-dimension-5": "4rem",
  "betterplace-dimension-6": "5rem",
  "betterplace-dimension-7": "6.25rem",
  "betterplace-dimension-8": "12.5rem",
  "betterplace-dimension-9": "20rem",
  "betterplace-dimension-10": "30rem",
  "betterplace-dimension--1": "0.75rem",
  "betterplace-dimension--2": "0.5rem",
  "betterplace-dimension--3": "0.25rem",
  "betterplace-dimension--4": "0.125rem",
  "betterplace-dimension--5": "0.0625rem",
  "betterplace-dimension-none": "0rem",
  "betterplace-dimension-negative-1": "-0.75rem",
  "betterplace-dimension-negative-2": "-0.5rem",
  "betterplace-dimension-negative-3": "-0.25rem",
  "betterplace-dimension-negative-4": "-0.125rem",
  "betterplace-dimension-negative-5": "-0.0625rem",
  "betterplace-opacity-low": "10%",
  "betterplace-opacity-md": "50%",
  "betterplace-opacity-high": "90%",
  "betterplace-letter-spacing-0": "0%",
  "betterplace-letter-spacing-increased": "150%",
  "betterplace-letter-spacing-decreased": "-5%",
  "betterplace-paragraph-spacing-0": "0",
  "betterplace-paragraph-spacing-md": "0.75rem",
  "betterplace-paragraph-spacing-sm": "0.5rem",
  "betterplace-color-gray-100": "#f8f8f8",
  "betterplace-color-gray-200": "#eeeeee",
  "betterplace-color-gray-300": "#cccccc",
  "betterplace-color-gray-400": "#acacac",
  "betterplace-color-gray-500": "#919191",
  "betterplace-color-gray-600": "#7b7b7b",
  "betterplace-color-gray-700": "#636363",
  "betterplace-color-gray-800": "#3f3f3f",
  "betterplace-color-gray-900": "#282828",
  "betterplace-color-green-100": "#f5fccc",
  "betterplace-color-green-200": "#eaf99a",
  "betterplace-color-green-300": "#d6ef66",
  "betterplace-color-green-400": "#bedf40",
  "betterplace-color-green-500": "#9ecb0a",
  "betterplace-color-green-600": "#85ac1c",
  "betterplace-color-green-700": "#549205",
  "betterplace-color-green-800": "#357c00",
  "betterplace-color-green-900": "#2a5807",
  "betterplace-color-purple-100": "#f7dae8",
  "betterplace-color-purple-200": "#f0b7d8",
  "betterplace-color-purple-300": "#d387ba",
  "betterplace-color-purple-400": "#a75c96",
  "betterplace-color-purple-500": "#833c79",
  "betterplace-color-purple-600": "#6d2c64",
  "betterplace-color-purple-700": "#612058",
  "betterplace-color-purple-800": "#4d1a43",
  "betterplace-color-purple-900": "#381835",
  "betterplace-color-teal-100": "#e4fbee",
  "betterplace-color-teal-200": "#c9f7e2",
  "betterplace-color-teal-300": "#a7e7d0",
  "betterplace-color-teal-400": "#88d0bd",
  "betterplace-color-teal-500": "#60b2a4",
  "betterplace-color-teal-600": "#309b89",
  "betterplace-color-teal-700": "#2b8475",
  "betterplace-color-teal-800": "#1e6761",
  "betterplace-color-teal-900": "#114e47",
  "betterplace-color-yellow-100": "#fff4d2",
  "betterplace-color-yellow-200": "#fbe8af",
  "betterplace-color-yellow-300": "#f9da7c",
  "betterplace-color-yellow-400": "#f6ce46",
  "betterplace-color-yellow-500": "#f3b700",
  "betterplace-color-yellow-600": "#f3a900",
  "betterplace-color-yellow-700": "#f39600",
  "betterplace-color-yellow-800": "#d86c00",
  "betterplace-color-yellow-900": "#c44400",
  "betterplace-color-red-100": "#fee2e2",
  "betterplace-color-red-200": "#ffc4c4",
  "betterplace-color-red-300": "#fca5a5",
  "betterplace-color-red-400": "#f87171",
  "betterplace-color-red-500": "#ef4444",
  "betterplace-color-red-600": "#dc2626",
  "betterplace-color-red-700": "#d32b43",
  "betterplace-color-red-800": "#b11b3e",
  "betterplace-color-red-900": "#931c38",
  "betterplace-color-light": "#ffffff",
  "betterplace-color-darkness": "#000000",
  "betterplace-color-blue-pruple-500": "#7d83ff",
  "betterplace-color-blue-purple-600": "#4557cb",
  "betterplace-color-blue-dark": "#245d8c",
  "betterplace-color-red-alt": "#fc440f",
  "betterplace-color-cream": "#fbfbf2",
  "betterplace-color-opacity-white-40": "rgba(255, 255, 255, 0.4)",
  "betterplace-color-opacity-white-70": "rgba(255, 255, 255, 0.7)",
  "betterplace-color-opacity-black-10": "rgba(0, 0, 0, 0.1)",
  "betterplace-color-opacity-black-20": "rgba(0, 0, 0, 0.2)",
  "betterplace-color-opacity-black-30": "rgba(0, 0, 0, 0.3)",
  "betterplace-color-opacity-black-40": "rgba(0, 0, 0, 0.4)",
  "betterplace-color-opacity-black-50": "rgba(0, 0, 0, 0.5)",
  "betterplace-color-opacity-black-60": "rgba(0, 0, 0, 0.6)",
  "betterplace-color-opacity-black-70": "rgba(0, 0, 0, 0.7)",
  "betterplace-color-opacity-black-80": "rgba(0, 0, 0, 0.8)",
  "betterplace-color-opacity-black-90": "rgba(0, 0, 0, 0.9)",
  "betterplace-me-color-orange-500": "#ff854d",
  "betterplace-me-color-orange-600": "#DB6038",
  "betterplace-me-color-orange-800": "#6F1F06",
  "betterplace-me-color-blue-100": "#E0F4F9",
  "betterplace-me-color-blue-500": "#00A0C8",
  "betterplace-me-color-blue-700": "#005D90",
  "betterplace-me-color-green-special": "#EBF2E6",
  "betterplace-external-color-blue-facebook": "#4E69A2",
  "betterplace-external-color-blue-twitter": "#1DA1F2",
  "betterplace-external-color-pink-instagram": "#E65083",
  "betterplace-external-color-blue-linkedin": "#0A66C2",
  "betterplace-external-color-red-sparkasse": "#ff0000",
  "betterplace-font-families-fira-sans": "Fira Sans",
  "betterplace-font-weights-regular": 400,
  "betterplace-font-weights-bold": 700,
  "betterplace-text-case-none": "none",
  "betterplace-text-case-capitalize": "capitalize",
  "betterplace-text-decoration-none": "none",
  "betterplace-text-decoration-underline": "underline",
  "betterplace-line-heights-xs": 1.1,
  "betterplace-line-heights-sm": 1.2,
  "betterplace-line-heights-base": 1.5,
  "betterplace-variables-min-font-size": "1rem",
  "betterplace-variables-max-font-size": "1.125rem",
  "betterplace-variables-fluid-root-font-size": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-variables-font-size-scale-ratio": "1.1892",
  "betterplace-variables-width-of-zero-glyph": "0.5583",
  "betterplace-units-ch": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem) * 0.5583",
  "betterplace-units-rem": "min(max(1rem + (90rem - 22.5rem) * (1.125 - 1) / (90 - 22.5), 1rem), 1.125rem)",
  "betterplace-font-size-0": "1rem",
  "betterplace-font-size-1": "1.189rem",
  "betterplace-font-size-2": "1.414rem",
  "betterplace-font-size-3": "1.682rem",
  "betterplace-font-size-4": "2rem",
  "betterplace-font-size-5": "2.378rem",
  "betterplace-font-size-6": "2.828rem",
  "betterplace-font-size-7": "3.363rem",
  "betterplace-font-size-8": "4rem",
  "betterplace-font-size-9": "4.757rem",
  "betterplace-font-size-10": "5.657rem",
  "betterplace-font-size--3": "0.595rem",
  "betterplace-font-size--2": "0.707rem",
  "betterplace-font-size--1": "0.878rem",
  "betterplace-measure-2xs": "25ch",
  "betterplace-measure-xs": "36ch",
  "betterplace-measure-sm": "49ch",
  "betterplace-measure-md": "64ch",
  "betterplace-measure-lg": "81ch",
  "betterplace-measure-xl": "100ch",
  "betterplace-measure-2xl": "121ch",
  "betterplace-viewport-width": "90rem",
  "betterplace-viewport-min": "22.5rem",
  "betterplace-viewport-max": "90rem",
  "betterplace-typography-xs": {
    fontWeight: 400,
    fontSize: "0.707rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-narrow": {
    fontWeight: 400,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-sm-bold-narrow": {
    fontWeight: 700,
    fontSize: "0.878rem",
    lineHeight: 1.2,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base": {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-base-bold": {
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg": {
    fontWeight: 400,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-lg-bold": {
    fontWeight: 700,
    fontSize: "1.189rem",
    lineHeight: 1.5,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl": {
    fontWeight: 400,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-xl-bold": {
    fontWeight: 700,
    fontSize: "1.682rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-2xl-bold": {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-3xl-bold": {
    fontWeight: 700,
    fontSize: "3.363rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-typography-4xl-bold": {
    fontWeight: 700,
    fontSize: "4rem",
    lineHeight: 1.1,
    fontFamily: "Fira Sans"
  },
  "betterplace-sizing-fix-1": "20rem",
  "betterplace-sizing-fix-2": "23.4375rem",
  "betterplace-sizing-fix-3": "48.75rem",
  "betterplace-sizing-fix-4": "60rem",
  "betterplace-sizing-fix-5": "52.875rem",
  "betterplace-sizing-fix-6": "90rem",
  "betterplace-shadow-outer-neutral-soft-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-neutral-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "rgba(0, 0, 0, 0.5)"
  },
  "betterplace-shadow-outer-green-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-green-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-outer-red-hard-1": {
    x: "0rem",
    y: "0rem",
    blur: "0.0625rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-2": {
    x: "0rem",
    y: "0rem",
    blur: "0.125rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-3": {
    x: "0rem",
    y: "0rem",
    blur: "0.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-4": {
    x: "0rem",
    y: "0rem",
    blur: "0.5rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-5": {
    x: "0rem",
    y: "0rem",
    blur: "0.75rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-red-hard-6": {
    x: "0rem",
    y: "0rem",
    blur: "1.25rem",
    color: "#d32b43"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-1": {
    x: "0rem",
    y: "-0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-2": {
    x: "0rem",
    y: "-0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-3": {
    x: "0rem",
    y: "-0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-4": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-5": {
    x: "0rem",
    y: "-0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-outer-neutral-soft-upwards-6": {
    x: "0rem",
    y: "-0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-neutral-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "rgba(0, 0, 0, 0.2)"
  },
  "betterplace-shadow-inner-red-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-red-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#931c38"
  },
  "betterplace-shadow-inner-green-1": {
    x: "0rem",
    y: "0.0625rem",
    blur: "0.125rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-2": {
    x: "0rem",
    y: "0.125rem",
    blur: "0.25rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-3": {
    x: "0rem",
    y: "0.25rem",
    blur: "0.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-4": {
    x: "0rem",
    y: "0.5rem",
    blur: "1rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-5": {
    x: "0rem",
    y: "0.5rem",
    blur: "1.5rem",
    color: "#2a5807"
  },
  "betterplace-shadow-inner-green-6": {
    x: "0rem",
    y: "0.75rem",
    blur: "3rem",
    color: "#2a5807"
  }
}, Pr = (e) => e.substring(1, 3) === "00" && e.length === 9 ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEX////Z5exdL3Q7AAAAGklEQVQY02NgAAFGRkaa0gzo4gzo6hgGiTsANBAAYf1ZdsIAAAAASUVORK5CYII=')" : e;
function q(e) {
  const t = Ir[e];
  return typeof t != "object" && typeof t != "number" ? t : "#";
}
const ut = G(function({
  onClick: t,
  color: r,
  triggerSwatch: n = !1,
  className: a,
  "aria-describedby": o,
  ...i
}, c) {
  return /* @__PURE__ */ p.jsxs(
    "button",
    {
      ...i,
      className: I(X.swatch, a, {
        [X.activeSwatch]: n
      }),
      onClick: t,
      ref: c,
      type: "button",
      "aria-roledescription": n ? "colour well" : "button",
      "aria-describedby": o,
      children: [
        /* @__PURE__ */ p.jsx(
          "div",
          {
            style: {
              background: Pr(r)
            },
            className: X.swatchInner
          }
        ),
        /* @__PURE__ */ p.jsx("span", { className: "sr-only", children: r })
      ]
    }
  );
});
function jr({
  color: e,
  presetColors: t,
  onChange: r,
  hexInputLabel: n,
  label: a,
  id: o,
  classNames: i,
  error: c,
  ...l
}) {
  const s = ge(), d = o || l.name || s, y = D(null), b = D(null);
  return /* @__PURE__ */ p.jsx(
    Ye,
    {
      name: l.name,
      id: d,
      label: a,
      error: c,
      className: I(X.picker, i == null ? void 0 : i.label),
      errorDisplay: "border",
      children: /* @__PURE__ */ p.jsx(
        Sr,
        {
          className: X.dropdown,
          trigger: /* @__PURE__ */ p.jsx(
            ut,
            {
              ...l,
              "aria-describedby": `label-${d}`,
              id: d,
              color: e,
              ref: b,
              triggerSwatch: !0,
              className: I(i == null ? void 0 : i.trigger, { [X.errorSwatch]: c })
            }
          ),
          Anchor: Ot,
          children: /* @__PURE__ */ p.jsx("div", { className: X.popover, ref: y, children: /* @__PURE__ */ p.jsxs("div", { className: X.pickerSwatches, children: [
            t.map((m) => /* @__PURE__ */ At(
              Mr,
              {
                ...l,
                key: m,
                color: m,
                onClick: () => {
                  r(m);
                },
                className: I({
                  [X.activeSwatch]: m === e,
                  [X.inactiveSwatch]: m !== e
                })
              }
            )),
            /* @__PURE__ */ p.jsx(Lr, { id: d, color: e, onChange: r, alpha: !0, prefixed: !0, className: X.input })
          ] }) })
        }
      )
    }
  );
}
function Mr({ onClick: e, ...t }) {
  const { hidePopup: r } = oe(), n = T(
    (a) => {
      r(), e == null || e(a);
    },
    [e, r]
  );
  return /* @__PURE__ */ p.jsx(ut, { ...t, onClick: n });
}
function Lr({ onKeyDown: e, ...t }) {
  const { hidePopup: r } = oe(), n = T(
    (a) => {
      e == null || e(a), a.key === "Enter" && r();
    },
    [e, r]
  );
  return /* @__PURE__ */ p.jsx(Er, { ...t, onKeyDown: n });
}
function xn({
  onColorChange: e,
  transparentOption: t = !1,
  value: r,
  hexInputLabel: n,
  label: a,
  ...o
}) {
  const i = [
    q("betterplace-color-light"),
    q("betterplace-color-green-500"),
    q("betterplace-color-green-700"),
    q("betterplace-color-green-900"),
    q("betterplace-color-yellow-400"),
    q("betterplace-me-color-orange-500"),
    q("betterplace-color-red-700"),
    q("betterplace-color-red-900"),
    // themeColor('orange-700'),
    // themeColor('blue-400'),
    // themeColor('blue-700'),
    // themeColor('blue-900'),
    q("betterplace-color-purple-400"),
    q("betterplace-color-purple-700"),
    q("betterplace-color-gray-500"),
    q("betterplace-color-gray-700"),
    q("betterplace-color-gray-900")
  ].filter(Boolean);
  return t && i.push("#00000000"), /* @__PURE__ */ p.jsx(
    jr,
    {
      ...o,
      color: `#${r === "transparent" ? "00000000" : r}`,
      presetColors: i,
      onChange: e,
      hexInputLabel: n,
      label: a
    }
  );
}
function ke(e, t, r, n, a) {
  return (e - t) * (a - n) / (r - t) + n;
}
function dt(e, t) {
  const r = e + t;
  return r > 360 ? r - 360 : r;
}
function pt() {
  return Math.random() > 0.5;
}
const ft = [0, 0, 1], Ne = [
  // dual axis rotations (a bit more realistic)
  [1, 1, 0],
  [1, 0, 1],
  [0, 1, 1],
  // single axis rotations (a bit dumber)
  [1, 0, 0],
  [0, 1, 0],
  ft
];
function Fr([e, t, r]) {
  const [n, a, o] = ft;
  return n === e && a === t && o === r;
}
function vn(e) {
  return !!Ne[e] && !Fr(Ne[e]) && pt();
}
function te(e, t = 4) {
  const r = Math.pow(10, t);
  return Math.round(e * r) / r;
}
function mt(e) {
  return te(e, 2).toString().replace(".", "-");
}
const qe = 200, Dr = 800, Or = 0.8, Br = 0.33, Re = 0.5, Tn = Ne.reduce((e, t, r) => ({
  ...e,
  [`--confetti-rotation-3d-${r}-50`]: t.map((n) => n / 2).join(),
  [`--confetti-rotation-3d-${r}-100`]: t.join()
}), {});
function _n(e, t, r) {
  const a = {
    "--confetti-landing-point-y": typeof t == "string" ? t : `${t}px`
  };
  for (const o of e) {
    const i = `${te(ke(Math.abs(dt(o, 90) - 180), 0, 180, -r / 2, r / 2), 2)}px`;
    a[`--confetti-degree-${mt(o)}-landing-point-x`] = i;
  }
  return a;
}
function Cn({ color: e, degree: t }, r, n, a, o) {
  const i = Math.round(Math.random() * (Dr - qe) + qe), c = r - Math.round(Math.random() * 1e3), s = Math.random() < Or ? te(Math.random() * Br, 2) : 0, d = s * -1, y = s, b = te(Math.abs(ke(Math.abs(dt(t, 90) - 180), 0, 180, -1, 1)), 4), m = te(Math.random() * Re, 4), x = te(Math.random() * n * (pt() ? 1 : -1), 4), _ = Re, g = te(Math.max(ke(Math.abs(t - 180), 0, 180, n, -n), 0), 4);
  return {
    "--confetti-animation-duration": `${c}ms`,
    "--confetti-particle-width": `${o ? a : Math.round(Math.random() * 4) + a / 2}px`,
    "--confetti-particle-height": `${o ? a : Math.round(Math.random() * 2) + a}px`,
    "--confetti-particle-color": e,
    "--confetti-rotation-animation-duration": `${i}ms`,
    "--confetti-landing-point-x": `var(--confetti-degree-${mt(t)}-landing-point-x)`,
    "--confetti-x1": s,
    "--confetti-x2": d,
    "--confetti-x3": y,
    "--confetti-x4": b,
    "--confetti-y1": m,
    "--confetti-y2": x,
    "--confetti-y3": _,
    "--confetti-y4": g
  };
}
const Sn = G(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, i = o ? M : t, c = o ? M : r;
  return /* @__PURE__ */ p.jsx(vt, { ...n, onChange: i, onClick: c, ref: a });
}), zr = G(function({
  onKeyDown: t,
  onKeyUp: r,
  onKeyDownCapture: n,
  onKeyUpCapture: a,
  onClick: o,
  ...i
}, c) {
  const { disabled: l } = i, s = l ? M : t, d = l ? M : r, y = l ? M : n, b = l ? M : a, m = l ? M : o;
  return /* @__PURE__ */ p.jsx(
    Tt,
    {
      ...i,
      onClick: m,
      onChange: s,
      onKeyUp: d,
      onKeyDownCapture: y,
      onKeyUpCapture: b,
      ref: c
    }
  );
}), $r = "_accordion_10f8a_1", Kr = "_accordionItem_10f8a_6", Hr = "_content_10f8a_11", Ur = "_wrapper_10f8a_16", qr = "_accordionSummary_10f8a_26", Rr = "_accordionIcon_10f8a_41", Wr = "_open_10f8a_59", Yr = "_closing_10f8a_72", ee = {
  accordion: $r,
  accordionItem: Kr,
  content: Hr,
  wrapper: Ur,
  accordionSummary: qr,
  accordionIcon: Rr,
  open: Wr,
  closing: Yr
}, bt = Ge({
  activeKey: void 0,
  prevKey: void 0,
  onTransitionFinished: (e) => {
  }
});
function Xr() {
  return Je(bt);
}
function An({
  label: e,
  id: t,
  className: r,
  children: n
}) {
  const { activeKey: a, onTransitionFinished: o, prevKey: i } = Xr(), [c, l] = K(a === t || i === t), s = D(null), d = D(null), y = T(
    (m) => {
      m.propertyName === "opacity" && m.target === s.current && a === t && (o(t), window.requestAnimationFrame(() => {
        if (!d.current) return;
        const x = ce(d.current);
        if (!x) return;
        const { top: _ } = d.current.getBoundingClientRect(), g = x === window ? 0 : x.offsetTop;
        x.scrollBy({ behavior: "smooth", top: _ - g });
      }));
    },
    [a, t, o]
  );
  E(() => {
    if (a === i) return;
    const m = setTimeout(() => l(a === t), 1);
    return () => clearTimeout(m);
  }, [t, a, i]);
  const b = T((m) => {
    m.key !== "Tab" && m.key !== " " && m.key !== "Enter" && M(m);
  }, []);
  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    /* @__PURE__ */ p.jsxs(
      "details",
      {
        open: !0,
        id: t,
        "aria-expanded": a === t ? "true" : "false",
        className: I(ee.accordionItem, { [ee.open]: c }, r),
        ref: d,
        children: [
          /* @__PURE__ */ p.jsxs(
            "summary",
            {
              onClick: M,
              onKeyDown: b,
              className: ee.accordionSummary,
              tabIndex: -1,
              children: [
                e,
                /* @__PURE__ */ p.jsx(We, { name: "arrowDown", className: ee.accordionIcon, size: "400" })
              ]
            }
          ),
          /* @__PURE__ */ p.jsx("div", { className: ee.wrapper, onTransitionEnd: y, ref: s, children: /* @__PURE__ */ p.jsx("div", { className: ee.content, children: n }) })
        ]
      }
    )
  );
}
function kn({
  defaultKey: e,
  children: t,
  activeKey: r,
  onTransitionFinished: n,
  className: a
}) {
  const [o, i] = K(void 0), [c, l] = K(r ?? e), s = T(
    (d) => {
      n == null || n(d, o), i(d);
    },
    [n, o]
  );
  return E(() => {
    r && (l(r), i(c));
  }, [r]), /* @__PURE__ */ p.jsx(Xe, { className: I(ee.accordion, a), children: /* @__PURE__ */ p.jsx(bt.Provider, { value: { activeKey: c, onTransitionFinished: s, prevKey: o }, children: t }) });
}
const Gr = "_modal_xnbvu_1", Jr = "_modalInner_xnbvu_52", Qr = "_modalHeader_xnbvu_56", Zr = "_closeButton_xnbvu_71", Vr = "_modalContent_xnbvu_77", en = "_modalActions_xnbvu_81", tn = "_portalTarget_xnbvu_90", rn = "_portalTargetInert_xnbvu_97", J = {
  modal: Gr,
  modalInner: Jr,
  modalHeader: Qr,
  closeButton: Zr,
  modalContent: Vr,
  modalActions: en,
  portalTarget: tn,
  portalTargetInert: rn
};
function nn(e) {
  e && (!("close" in e) || typeof e.close != "function" || e.close());
}
const Nn = G(function({
  id: t,
  isOpen: r,
  onClose: n,
  onOpen: a,
  onCloseComplete: o,
  onOpenComplete: i,
  title: c,
  customActions: l,
  children: s,
  manualFocusRef: d,
  closeText: y,
  closeOnClickOutside: b = !0,
  hideHeader: m = !1,
  hideActions: x = !1,
  classNames: _ = {}
}, g) {
  const [f, C] = Ze(g), A = D(null), [L, P] = K(!0), F = T(() => d || (A.current ? A : f), [d, f]), O = ge(), u = t || O, h = T(
    (w) => {
      var V;
      if (w.key === "Escape") return n(w);
      if (w.key !== "Tab") return;
      const v = (V = f.current) == null ? void 0 : V.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      ), k = v == null ? void 0 : v[0], Y = v == null ? void 0 : v[v.length - 1];
      if (w.shiftKey && document.activeElement === k) {
        w.preventDefault(), Y == null || Y.focus();
        return;
      }
      !w.shiftKey && document.activeElement === Y && (w.preventDefault(), k == null || k.focus());
    },
    [f, n]
  ), j = T(() => {
    o == null || o(), P(!0);
  }, [o]), S = T(() => {
    var w;
    i == null || i(), P(!0), (w = f.current) == null || w.removeAttribute("inert");
  }, [f, i]);
  E(() => {
    var v;
    P(!1);
    const w = f.current;
    if (w) {
      if (r) {
        w.showModal();
        const k = F();
        (v = k == null ? void 0 : k.current) == null || v.focus(), w.removeEventListener("transitionend", j), w.addEventListener("keydown", h), w.addEventListener("transitionend", S), a == null || a();
      } else
        w.removeEventListener("keydown", h), w.removeEventListener("transitionend", S), w.addEventListener("transitionend", j), nn(w), w.setAttribute("inert", "");
      return () => {
        w == null || w.removeEventListener("keydown", h), w.removeEventListener("transitionend", j), w.removeEventListener("transitionend", S);
      };
    }
  }, [F, h, r, f, j, a, S]);
  const $ = T(
    (w) => {
      const { current: v } = f;
      w.target === v && n(w);
    },
    [f, n]
  );
  return (
    // Disabled because HTML dialog brings keyboard interaction with it
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    /* @__PURE__ */ p.jsxs(
      "dialog",
      {
        id: u,
        ref: C,
        className: I(J.modal, _.modal),
        onCancel: n,
        onClick: b ? $ : void 0,
        "aria-labelledby": `modal-title-${u}`,
        "aria-modal": "true",
        title: c,
        tabIndex: -1,
        children: [
          /* @__PURE__ */ p.jsxs(Xe, { space: "400", className: I(J.modalInner, _.modalInner), children: [
            !m && /* @__PURE__ */ p.jsxs("div", { className: I(J.modalHeader, _.titleContainer), children: [
              /* @__PURE__ */ p.jsx(_t, { level: "h200", as: "h2", id: `modal-title-${u}`, children: c }),
              /* @__PURE__ */ p.jsx(
                zr,
                {
                  kind: "plain",
                  className: J.closeButton,
                  onClick: n,
                  ref: A,
                  type: "button",
                  iconName: "x",
                  iconProps: { title: y, color: "fg-content-primary" }
                }
              )
            ] }),
            /* @__PURE__ */ p.jsx("div", { className: I(J.modalContent, _.contentContainer), children: (r || !L) && s }),
            !x && /* @__PURE__ */ p.jsx("div", { className: I(J.modalActions, _.actionsContainer), children: l || /* @__PURE__ */ p.jsx(Mt, { onClick: n, type: "button", children: y }) })
          ] }),
          r && /* @__PURE__ */ p.jsxs(p.Fragment, { children: [
            /* @__PURE__ */ p.jsx("div", { id: "bp-portal-target", className: J.portalTarget, role: "alert" }),
            /* @__PURE__ */ p.jsx("div", { id: "bp-portal-target-inert", className: J.portalTargetInert, role: "alert" })
          ] })
        ]
      }
    )
  );
}), En = G(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, i = o ? M : t, c = o ? M : r;
  return /* @__PURE__ */ p.jsx(Ct, { ...n, onChange: i, onClick: c, ref: a });
}), on = "_input_1bj67_1", an = "_growWrap_1bj67_2", fe = {
  input: on,
  growWrap: an,
  "input--error": "_input--error_1bj67_29"
}, In = G(function({
  label: t,
  name: r,
  description: n,
  error: a,
  warning: o,
  id: i,
  required: c,
  className: l,
  onChange: s,
  errorDisplay: d,
  rows: y = 1,
  fullWidth: b,
  ...m
}, x) {
  const _ = D(null), g = [];
  return a && g.push(`${r}-error`), o && g.push(`${r}-warning`), n && g.push(`${r}-description`), /* @__PURE__ */ p.jsx(
    Ye,
    {
      label: t,
      name: r,
      description: n,
      error: a,
      warning: o,
      id: i || r,
      required: c,
      errorDisplay: d,
      fullWidth: b,
      /**
       * The following trick is used for the auto resizing textarea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
       * We need to make sure the same styles are applied to both the grow wrapper and the input
       */
      children: /* @__PURE__ */ p.jsx(
        "div",
        {
          className: I(fe.growWrap, l, {
            [fe.wrapError]: !!a,
            fullWidth: b && b !== "mobile-only",
            fullWidthOnMobile: b && b === "mobile-only"
          }),
          ref: _,
          children: /* @__PURE__ */ p.jsx(
            "textarea",
            {
              ...m,
              ref: x,
              id: i || r,
              name: r,
              "aria-describedby": g.length ? g.join(" ") : void 0,
              "aria-invalid": !!a,
              "aria-required": c,
              className: I(fe.input, l, {
                [fe["input--error"]]: !!a,
                fullWidth: b && b !== "mobile-only",
                fullWidthOnMobile: b && b === "mobile-only"
              }),
              rows: y,
              onChange: (f) => {
                var C;
                (C = _.current) == null || C.setAttribute("data-replicated-value", f.target.value), s && s(f);
              }
            }
          )
        }
      )
    }
  );
}), cn = "_button_1ijcr_1", ln = "_hiddenInput_1ijcr_58", ve = {
  button: cn,
  hiddenInput: ln,
  "size-large": "_size-large_1ijcr_64",
  "size-default": "_size-default_1ijcr_69",
  "size-small": "_size-small_1ijcr_74"
}, Pn = G(function({
  id: t,
  children: r,
  value: n,
  name: a,
  onChange: o,
  onClick: i,
  className: c,
  type: l = "radio",
  size: s = "default",
  disabled_: d,
  disabled: y,
  fullWidth: b,
  ...m
}, x) {
  const _ = I(ve.button, ve[`size-${s}`], c), g = ge(), C = `${t || a || g}-${String(n)}`, A = y || d;
  return /* @__PURE__ */ p.jsxs(
    "label",
    {
      htmlFor: C,
      className: I(
        {
          fullWidth: b && b !== "mobile-only",
          fullWidthOnMobile: b && b === "mobile-only"
        },
        _
      ),
      children: [
        /* @__PURE__ */ p.jsx(
          "input",
          {
            ...m,
            ref: x,
            id: C,
            "aria-disabled": A,
            type: l,
            value: n,
            name: a,
            onClick: A ? M : i,
            onChange: A ? M : o,
            className: ve.hiddenInput,
            disabled: d
          }
        ),
        r
      ]
    }
  );
}), jn = G(function({ onChange: t, onClick: r, ...n }, a) {
  const { disabled: o } = n, i = o ? M : t, c = o ? M : r;
  return /* @__PURE__ */ p.jsx(St, { ...n, onChange: i, onClick: c, ref: a });
});
function sn(e, t) {
  return {
    position: "absolute",
    inset: 0,
    transform: `translateY(${e * t}px)`,
    height: `${t}px`
  };
}
const Te = {
  Up: "Up",
  Down: "Down",
  None: null
};
function un({
  className: e,
  style: t,
  children: r,
  overscanRows: n,
  maxRows: a,
  rowHeight: o,
  rows: i,
  onScroll: c,
  initialRow: l
}) {
  const s = D(null), d = n || i, y = D(/* @__PURE__ */ new Map()), b = D(null), m = D((l ?? 0) * o), [x, _] = K(0), g = D(Te.None), f = a ?? x + (i + d) * 2 + 1, C = i * o, A = o * f;
  function L(S) {
    return y.current.has(S) || y.current.set(S, sn(S, o)), y.current.get(S);
  }
  const P = T(
    (S) => {
      const $ = Math.max(0, S - d), w = Math.min(
        f - 1,
        // don't render past the end of the list
        S + i + d
      );
      return [$, w];
    },
    [f, d, i]
  ), F = [], [O, u] = P(x);
  for (let S = O; S <= u; S++)
    F.push(/* @__PURE__ */ p.jsx(r, { index: S, style: L(S) }, S));
  const h = T(
    (S) => {
      b.current && clearTimeout(b.current), setTimeout(() => {
        c == null || c(S), b.current = null;
      }, 50);
    },
    [c]
  ), j = T(
    (S) => {
      const $ = m.current;
      m.current = S.currentTarget.scrollTop;
      const w = Math.floor(m.current / o);
      g.current = $ < m.current ? Te.Down : Te.Up, h(P(w)), _(w);
    },
    [P, h, o]
  );
  return E(() => {
    var S;
    l && ((S = s.current) == null || S.scrollTo({ left: 0, top: l * o, behavior: "instant" }), c == null || c(P(l)));
  }, [P, l, c, o]), E(() => () => {
    b != null && b.current && clearTimeout(b.current);
  }, []), /* @__PURE__ */ p.jsx(
    "div",
    {
      className: e,
      style: {
        ...t,
        overflowY: "auto",
        position: "relative",
        scrollBehavior: "smooth",
        height: C,
        willChange: "contents"
      },
      ref: s,
      onScroll: j,
      children: /* @__PURE__ */ p.jsx("div", { style: { position: "absolute", top: 0, left: 0, right: 0, height: A }, children: F })
    }
  );
}
const Mn = kt(un);
export {
  Q as A,
  dr as B,
  Pt as C,
  Sr as D,
  nt as E,
  pr as F,
  Pe as G,
  Ce as H,
  $e as I,
  fr as J,
  ce as K,
  kn as L,
  ot as M,
  ie as N,
  En as O,
  xr as P,
  Pn as Q,
  jn as R,
  Ze as S,
  In as T,
  z as U,
  Mn as V,
  Cn as a,
  Ne as b,
  Ot as c,
  H as d,
  Mt as e,
  mn as f,
  _n as g,
  xn as h,
  gn as i,
  yn as j,
  wn as k,
  bn as l,
  hn as m,
  Sn as n,
  zr as o,
  An as p,
  Nn as q,
  Tn as r,
  vn as s,
  sr as t,
  Xr as u,
  ur as v,
  Ve as w,
  ye as x,
  Ie as y,
  rt as z
};
